import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Battle } from 'app/model/battle';
import { SelectionModel, Fighter } from 'app/model/selection';
import { PlayerModel } from 'app/model/player';
import { CharacterModel } from 'app/model/character';

@Component({
  selector: 'app-battle',
  templateUrl: './battle.component.html',
  styleUrls: ['./battle.component.scss']
})
export class BattleComponent implements OnInit {


  @Input() selections: SelectionModel[];
  @Output() gotoPage: EventEmitter<number> = new EventEmitter<number>();
  p1: PlayerModel;
  p2: PlayerModel;

  quitSure:boolean=false;

  selectWinner:boolean=false;

  toggleSelectWinner(){
    this.selectWinner = !this.selectWinner;
  }

  @Input() battles: Array<Battle> = new Array<Battle>();
  currentBattle: Battle;

  constructor() { }

  ngOnInit() {
    this.p1 = this.selections[0].player;
    this.p2 = this.selections[1].player;

    console.log(this.selections);
    this.currentBattle = this.nextRound();

  }


  quitCheck(val:boolean){
    this.quitSure=val;
  }

  nextRound(): Battle {



    let battle = new Battle();
    battle.round = this.battles.length + 1;
    //battle.p1=this.selection[0].fighters.find(s=>.)
    battle.p1 = this.selections[0].fighters.find(s => s.alive === true);
    battle.p2 = this.selections[1].fighters.find(s => s.alive === true);

    battle.p1.revealed = true;
    battle.p2.revealed = true;

    this.battles.push(battle);

    console.log(battle);

    return battle;

  }

  setWinner(winner: Fighter, loser: Fighter) {

    winner.alive = true;
    loser.alive = false;

    this.currentBattle.winner = winner;
    this.currentBattle.loser = loser;

    this.gotoNextBattle();

    this.selectWinner = false;

  }

  gotoNextBattle(winner?) {

  

    if (!this.currentBattle.winner) {
      return;
    }

    if (this.selections[0].fighters.find(f => f.alive) && this.selections[1].fighters.find(f => f.alive)) {
      console.log(this.currentBattle);
      this.currentBattle = this.nextRound();
      console.log(this.currentBattle);
    } else {
      this.gotoPage.emit(3);
    }


  }


  showRandom(battleFighter: Fighter) {
    console.log("char:");
    //console.log(char);
    console.log(battleFighter);
    return battleFighter === this.currentBattle.p1 &&

      battleFighter.revealed;// || char.fighter===battleFighter.fighter ||  !char.isRandom;
  }


  goBack() {
    this.gotoPage.emit(1);
  }


}
