import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Battle } from 'app/model/battle';
import { SelectionModel, Fighter } from 'app/model/selection';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  @Input() selections:SelectionModel[];
  @Input() battles:Battle[];
  
  @Output() gotoPage: EventEmitter<number> = new EventEmitter();


  
  constructor() { }

  ngOnInit() {

    this.selections.forEach(s=>{
      s.fighters.forEach(f=>{
        f.revealed=true;
      })
    })

  }


  restart(){
    
    this.gotoPage.emit(0);
  }

}
