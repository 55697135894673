import { CharacterModel } from "app/model/character";
import { PlayerModel } from "app/model/player";
import { FightResult } from "app/enum/fight-result.enum";


export class Fighter{
    fighter:CharacterModel;
    isRandom:boolean;
    fights:Fight[];
    alive:boolean;
    revealed:boolean;
    

}



export class Fight{
    vs:CharacterModel;
    result: FightResult;
}


export class SelectionModel{

    player:PlayerModel;
    fighters:Fighter[];

}