import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { CharacterSelectComponent } from 'app/character-select/character-select.component';
import { ICharacterService, CharacterService } from 'app/service/character/character.service';
import { SelectionModel } from 'app/model/selection';
import { SelectionService } from 'app/service/selection/selection.service';
import { BattleComponent } from './battle/battle.component';
import { StartComponent } from './start/start.component';
import { FormsModule } from '@angular/forms';
import { ResultsComponent } from './results/results.component';


@NgModule({
  declarations: [
    AppComponent,
    CharacterSelectComponent,
    BattleComponent,
    StartComponent,
    ResultsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
  ],
  providers: [
    {provide:ICharacterService, useClass:CharacterService},
    SelectionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
