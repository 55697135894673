import { Injectable } from '@angular/core';
import { CharacterModel } from 'app/model/character';
import { SelectionModel } from 'app/model/selection';

export abstract class ISelectionService {


}


@Injectable()
export class SelectionService implements ISelectionService {


  selections: SelectionModel[] = [];

  teamSize: Number = 2;


  constructor() { }

  // addSelection(player: string, character: CharacterModel) {

  //   //let selectedPlayer:SelectionModel = this.getOrCreatePlayer(player);
  //   let pos = selectedPlayer.selection.push(character);
  //   selectedPlayer.selection[pos-1].selectedP1=pos;
  //   //console.log(selectedPlayer);


  // }

  // getOrCreatePlayer(playerName: string): SelectionModel {

  //   let selectedPlayer;
  //   if (this.selections){

  //      selectedPlayer = this.selections.find(s => s.player === playerName);
  //   }

  //   if (!selectedPlayer) {
  //     let newPlayer = new SelectionModel;
  //     newPlayer.player = playerName;
  //     newPlayer.selection=[];
  //     this.selections.push(newPlayer);
  //     return newPlayer;
  //   } 
    
  //   return selectedPlayer;

  // }




}
