import { SelectionModel, Fighter } from "app/model/selection";

export class Battle{

    round:number;
    p1:Fighter;
    p2:Fighter;
    winner:Fighter;
    loser:Fighter;

}