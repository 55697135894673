import { Injectable } from '@angular/core';
import { CharacterModel } from 'app/model/character';


export abstract class ICharacterService {

  abstract getCharacters(activeOnly?: boolean): CharacterModel[];
  abstract getCharacterByName(name: string): CharacterModel;
  abstract toggleCharacter(name, disabled): void;

}


@Injectable()
export class CharacterService implements ICharacterService {

  constructor() { }

  getCharacters(activeOnly: boolean = true): CharacterModel[] {

    let chars;
    if (activeOnly) {

      chars = characters.filter(c => !c.disabled);
    } else {
      chars = characters;

    }
    // console.log(chars);
    return chars;
  }


  getCharacterByName(name: string): CharacterModel {

    return characters.find(c => c.name === name);


  }

  toggleCharacter(name: string, disabled: boolean) {

    //console.log(name.toLocaleLowerCase());
    try {

      let char = characters.find(c => c.name.toLocaleLowerCase() === name.toLocaleLowerCase());
      char.disabled = disabled;
    } catch (error) {

      console.log('no character to toggle');
    }
  }

  characterIsSelected(char: CharacterModel): boolean {
    if (char.selectedP1 || char.selectedP2) {
      return true;
    }

    return false;
  }


}


const characters: CharacterModel[] = [
  <CharacterModel>{
    name: "Akuma",
    longName: "Akuma",
    profileUrl: "akuma.png"
  },
  <CharacterModel>{
    name: "Asuka",
    longName: "Asuka Kazama",
    profileUrl: "asuka.png"
  },
  <CharacterModel>{
    name: "Alisa",
    longName: "Alisa Boskonovic",
    profileUrl: "alisa.png"
  },
  <CharacterModel>{
    name: "Bob",
    longName: "Bob Williams",
    profileUrl: "bob.png"
  },
  <CharacterModel>{
    name: "Bryan",
    longName: "Bryan",
    profileUrl: "bryan.png"
  },
  <CharacterModel>{
    name: "Chloe",
    longName: "Lucky Chloe",
    profileUrl: "chloe.png"
  },
  <CharacterModel>{
    name: "Claudio",
    longName: "Claudio",
    profileUrl: "claudio.png"
  },
  <CharacterModel>{
    name: "Devil Jin",
    longName: "Devil Jin",
    profileUrl: "deviljin.png"
  },
  <CharacterModel>{
    name: "Dragunov",
    longName: "Dragunov",
    profileUrl: "dragunov.png"
  },
  <CharacterModel>{
    name: "Eddy",
    longName: "Eddy",
    profileUrl: "eddy.png"
  },
  <CharacterModel>{
    name: "Eliza",
    longName: "Eliza",
    profileUrl: "eliza.png"
  },
  <CharacterModel>{
    name: "Feng",
    longName: "Feng",
    profileUrl: "feng.png"
  },
  <CharacterModel>{
    name: "Gigas",
    longName: "Gigas",
    profileUrl: "gigas.png"
  },
  <CharacterModel>{
    name: "Heihachi",
    longName: "Heihachi",
    profileUrl: "heihachi.png"
  },
  <CharacterModel>{
    name: "Hwoarang",
    longName: "Hwoarang",
    profileUrl: "hwoarang.png"
  },
  <CharacterModel>{
    name: "Jack7",
    longName: "Jack7",
    profileUrl: "jack.png"
  },
  <CharacterModel>{
    name: "Jin",
    longName: "Jin",
    profileUrl: "jin.png"
  },
  <CharacterModel>{
    name: "Josie",
    longName: "Josie",
    profileUrl: "josie.png"
  },
  <CharacterModel>{
    name: "Katarina",
    longName: "Katarina",
    profileUrl: "katarina.png"
  },
  <CharacterModel>{
    name: "Kazumi",
    longName: "Kazumi",
    profileUrl: "kazumi.png"
  },
  <CharacterModel>{
    name: "Kazuya",
    longName: "Kazuya Mishima",
    profileUrl: "kazuya.png"
  },
  <CharacterModel>{
    name: "King",
    longName: "King",
    profileUrl: "king.png"
  },
  <CharacterModel>{
    name: "Kuma",
    longName: "Kuma",
    profileUrl: "kuma.png"
  },
  <CharacterModel>{
    name: "Lars",
    longName: "Lars",
    profileUrl: "lars.png"
  },
  <CharacterModel>{
    name: "Law",
    longName: "Law",
    profileUrl: "law.png"
  },
  <CharacterModel>{
    name: "Lee",
    longName: "Lee",
    profileUrl: "lee.png"
  },
  <CharacterModel>{
    name: "Leo",
    longName: "Leo",
    profileUrl: "leo.png"
  },
  <CharacterModel>{
    name: "Lili",
    longName: "Lili",
    profileUrl: "lili.png"
  },
  <CharacterModel>{
    name: "Ling",
    longName: "Ling",
    profileUrl: "ling.png"
  },
  <CharacterModel>{
    name: "Miguel",
    longName: "Miguel",
    profileUrl: "miguel.png"
  },
  <CharacterModel>{
    name: "Negan",
    longName: "Negan",
    profileUrl: "negan.png",
    disabled: true
  },
  <CharacterModel>{
    name: "Nina",
    longName: "Nina Williams",
    profileUrl: "nina.png"
  },
  <CharacterModel>{
    name: "Noctis",
    longName: "Noctis",
    profileUrl: "noctis.png",
    disabled: true
  },
  <CharacterModel>{
    name: "Panda",
    longName: "Panda",
    profileUrl: "panda.png"
  },
  <CharacterModel>{
    name: "Paul",
    longName: "Paul Phoenix",
    profileUrl: "paul.png"
  },
  <CharacterModel>{
    name: "Raven",
    longName: "Raven",
    profileUrl: "raven.png"
  },
  <CharacterModel>{
    name: "Shaheen",
    longName: "Shaheen",
    profileUrl: "shaheen.png"
  },
  <CharacterModel>{
    name: "Steve",
    longName: "Steve",
    profileUrl: "steve.png"
  },
  <CharacterModel>{
    name: "Yoshimitsu",
    longName: "Yoshimitsu",
    profileUrl: "yoshimitsu.png"
  },
] 