import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CharacterModel } from 'app/model/character';
import { ICharacterService } from 'app/service/character/character.service';
import { SelectionService } from 'app/service/selection/selection.service';
import { SelectionModel, Fight, Fighter } from 'app/model/selection';
import { PlayerModel } from 'app/model/player';

@Component({
  selector: 'app-character-select',
  templateUrl: './character-select.component.html',
  styleUrls: ['./character-select.component.scss']
})
export class CharacterSelectComponent implements OnInit {


  @Output() gotoPage: EventEmitter<number> = new EventEmitter();
  @Input() teamSize: number = 1;



  allChars: CharacterModel[];

  p1Active: boolean = true;
  Arr: Array<any>;



  @Input() selections: SelectionModel[] = [];
  activeSelection: SelectionModel;
  p1: PlayerModel;
  p2: PlayerModel;


  setP1Active(active: boolean) {
    this.p1Active = active;
    this.setActiveSelection();
  }

  setActiveSelection() {

    this.activeSelection = this.p1Active ? this.selections[0] : this.selections[1];
  }



  constructor(
    private characterService: ICharacterService,
    private selectionService: SelectionService,
  ) {

  }

  ngOnInit() {
    this.allChars = this.characterService.getCharacters();
    //console.log(this.allChars);

    //    this.selections.push({player:this.p1, fighters:[]})
    ///  this.selections.push({player:this.p2, fighters:[]})
    this.p1 = this.selections[0].player;
    this.p2 = this.selections[1].player;

    this.setActiveSelection();


    //console.log(this.selections);


  }

  onSelect(fighter: CharacterModel) {
    //console.log("hello");
    //this.selectionService.addSelection("P1", char);

    if (this.activeSelection.fighters.length >= this.teamSize) {
      return;
    }

    if (!this.activeSelection.fighters.some(f => f.fighter === fighter)) {
      this.activeSelection.fighters.push(<Fighter>
        { fighter: fighter, isRandom: false, fights: [], alive: true });
    }

  }

  addRandom() {

    if (this.activeSelection.fighters.length >= this.teamSize) {
      return;
    }

    let random = new Fighter;
    random.isRandom = true;

    this.activeSelection.fighters.push(random);

    //console.log("adding random")
    //console.log(this.activeSelection);
  }

  countRemainingSelect(): number {
    return this.teamSize - this.activeSelection.fighters.length;
  }
  countRemainingSelectByIndex(index: number): number {
    return this.teamSize - this.selections[index].fighters.length;
  }

  undoSelect() {

    if (!this.activeSelection.fighters.length) {
      return;
    }

    console.log("removing")

    this.activeSelection.fighters.pop();
    console.log(this.activeSelection);


  }


  characterIsSelected(fighter: CharacterModel): boolean {
    //console.console.log("char is sel");
    //console.log(this.activeSelection);

    if (this.activeSelection) {

      return this.activeSelection.fighters.some(f => f.fighter === fighter) || false;
    }

    // if (char. || char.selectedP2) {
    //   return true;
    // }

    return false;
  }

  selectPostion(index: number, fighter: CharacterModel): number {

    return this.selections[index].fighters.findIndex(f => f.fighter === fighter) + 1;
  }


  fight() {


    this.selections.forEach(selection => {

      selection.fighters.filter(i => i.isRandom).forEach((c, index) => {

        while (!c.fighter) {

          let fighter = this.allChars[Math.floor(Math.random() * (this.allChars.length - 1))];
          if (!selection.fighters.some(f => f.fighter === fighter)) {
            c = <Fighter>
              { fighter: fighter, isRandom: true, fights: [], alive: true, revealed: false };

            selection.fighters[index] = c;
          }
        }

      });


      while (selection.fighters.length < this.teamSize) {

        let fighter = this.allChars[Math.floor(Math.random() * (this.allChars.length - 1))]

        //let available = this.allChars.filter(a=>selection.fighters.includes(f=)

        if (!selection.fighters.some(f => f.fighter === fighter)) {
          selection.fighters.push(<Fighter>
            { fighter: fighter, isRandom: true, fights: [], alive: true, revealed: false });
        }
        //console.log("randomising");
        //console.log(selection);
      }

      //console.log(selection);
    });






    console.log("fight");
    this.gotoPage.emit(2);
  }

  back() {
    this.gotoPage.emit(0);
  }

}
