import { Component } from '@angular/core';
import { PlayerModel } from 'app/model/player';
import { SelectionModel } from 'app/model/selection';
import { Battle } from 'app/model/battle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  teamSize:number=4;

  p1: PlayerModel = { id: 1, name: "P1" }
  p2: PlayerModel = { id: 2, name: "P2" }

  selections: SelectionModel[] = [];
  battles: Array<Battle> = new Array<Battle>();

  page: number = 0;

  ngOnInit() {

    this.selections.push({ player: this.p1, fighters: [] })
    this.selections.push({ player: this.p2, fighters: [] })
  }


  gotoPage(page: number) {
    console.log("going to page: " + page);

    if (page===0){
      this.selections=[];
      this.battles=[];
      this.selections.push({ player: this.p1, fighters: [] })
      this.selections.push({ player: this.p2, fighters: [] })
    }

    this.page = page;


  }



}
