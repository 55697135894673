import { Component, OnInit, EventEmitter, Output, Input, } from '@angular/core';
import { NgModel } from '@angular/forms';
import { SelectionModel } from 'app/model/selection';
import { ICharacterService } from '../service/character/character.service';
import { CharacterModel } from '../model/character';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {


  @Input() teamSize: number;
  @Output() teamSizeChange: EventEmitter<number> = new EventEmitter();

  toggleEliza: boolean = false;

  @Input() selections: SelectionModel[] = [];

  @Output() gotoPage: EventEmitter<number> = new EventEmitter();


  chars: CharacterModel[];
  showdlc:boolean=false;

  constructor(
    private characterService: ICharacterService,

  ) { }

  ngOnInit() {
    this.chars = this.characterService.getCharacters(false);
    console.log(this.chars);
  }

  setTeamSize(size: number) {
    this.teamSize = size;
    this.teamSizeChange.emit(size);
    console.log(this.teamSize);
  }

  toggledlc(){
    this.showdlc = !this.showdlc;
  }

  toggleChar(char: CharacterModel) {




    char.disabled = !char.disabled;

    let available = this.chars.filter(c => !c.disabled).length;

    if (available < 8) {

      this.setTeamSize(available);

      if (available < 2) {
        char.disabled = false;
      }
    }


    console.log("toggled " + char.name + " to " + char.disabled);
    //this.characterService.toggleCharacter(charName, toggle )

  }

  nextPage() {
    if (!this.selections[0].player.name.trim()) {
      this.selections[0].player.name = "Player 1";
    }
    if (!this.selections[1].player.name.trim()) {
      this.selections[1].player.name = "Player 2";
    }
    this.gotoPage.emit(1);
  }

}
